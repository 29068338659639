 .avatar-dropdown-open {
     border: 5px solid rgb(98, 26, 231);
     border-radius: 25px;
 }

 .profile_popover {
     animation: dropdownAnimation 3s ease;
 }

 .profile_popover .MuiPopover-paper .avatar_dropdown {
     cursor: pointer;
     position: relative;
     font-size: 20px;
 }

 .avatar_dropdown:hover {
     background: linear-gradient(135deg, rgb(153, 159, 180) 25%, rgb(51, 54, 197) 100%);
     color: white;
 }

 .avatar_dropdown:hover .MuiSvgIcon-root {
     color: white;
 }


 .user_card {
     height: auto;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: auto;
     max-width: 700px;
     border-radius: 3rem;
 }

 .userProfile {
     background: linear-gradient(#09c6f9, #087ee1, #045de9);
     border-radius: 3rem 0 0 3rem;
     height: 22.3rem;
     width: 125%;
     margin-left: -11.5px;
     padding-top: 20px;
 }

 .userProfileText {
     margin-bottom: 25px;
 }

 .infoText {
     margin-bottom: '20px';
     padding-bottom: '5px';
     border-bottom: '1px solid #000';
     font-weight: '600';
     text-align: 'center';
 }

 .userDetails {
     padding-left: 50px;
     padding-top: 20px;
 }

 .profile-image {
     width: 100px;
     height: 100px;
     border-radius: 50%;
     margin-bottom: 15px;
     margin-right: 15px;
 }

 .facebook,
 .twitter,
 .instagram,
 .linkedin {
     font-size: 20px;
     cursor: pointer;
     color: #007bff;
 }

 .facebook:hover,
 .twitter:hover,
 .instagram:hover,
 .linkedin:hover {
     color: #09386b;
 }

 @media (max-width: 768px) {
     .user_card {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: auto;
         max-width: 700px;
         border-radius: 3rem;
     }

     .userProfile {
         border-radius: 3rem 3rem 0 0;
         width: 106%;
         height: 250px;
         padding-top: 0;
         /* border-radius: 0; */
         margin-left: -10px;
         margin-top: 2px;
     }

     .userProfileText {
         margin-bottom: 0px;
     }

     .userDetails {
         width: 105%;
         margin-left: -10px;
     }
 }