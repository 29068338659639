@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code, html {
  font-family: 'Poppins', sans-serif !important;
}
:root {
  --h1-font-size: 2.5rem;
  --h2-font-size: 2rem;
  --h3-font-size: 1.75rem;
  --h4-font-size: 1.5rem;
  --h5-font-size: 1.25rem;
  --h6-font-size: 1rem;
}
.holder {
  background: #fff;
  min-height: 100vh;
}
.dSidebarTypo{
font-family: 'Poppins' !important;
font-size: 14px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
}
.heading {
font-family: 'Poppins' !important;
font-size: 40px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #444444;
}
p { 
  font-size: 14px;
  margin-bottom: 0 !important;
}

h1, h2, h3, h4, h5, h6 ,p{
  font-family: 'Poppins' !important;
}
h1 {
  font-size: var(--h1-font-size) !important;
}

h2 {
  font-size: var(--h2-font-size) !important;
}

h3 {
  font-size: var(--h3-font-size) !important;
}

h4 {
  font-size: var(--h4-font-size) !important;
}

h5 {
  font-size: var(--h5-font-size) !important;
}

h6 {
  font-size: var(--h6-font-size) !important;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem !important;
  }

  h2 {
    font-size: 1.8rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
  }
  
  h4 {
    font-size: 1.3rem !important;
  }
  
  h5 {
    font-size: 1rem !important;
  }
  
  h6 {
    font-size: 0.75rem !important;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem !important; 
  }

  h2 {
    font-size: 1.3rem !important; 
  }
  h3 {
    font-size: 1.2rem !important;
  }
  
  h4 {
    font-size: 1rem !important;
  }
  
  h5 {
    font-size: 0.85rem !important;
  }
  
  h6 {
    font-size: 0.5rem !important;
  }
}