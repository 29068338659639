/* .content-container {
    display: flex;
    align-items: center;
    justify-content: center;

  
  }
  .Registration-Form > div {

    padding: 3px;
    margin-top: 10px;
    width: 350px;
  }
  .Registration-Form  {
    margin-top: 80px;
  }
  
  @media (max-width: 768px) {
    .content-container {
      flex-direction: column; 
      align-items: center;
    }
  
    .Registration-Form > div {
      width: 100%; 
    }
  } */
  .small-swal {
    max-width: 300px;
    max-height:400 px;
   /* Set your desired maximum width */
  }
   
 