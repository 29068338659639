

.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
 
  
  }
  
  .logo {
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
  
  }

  .rectangle-swal {
    width: 400px;
    height: 200px;
    font-size: 14px; 
    top: 50px;
  }
  @media (max-width: 768px) {

    .content-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .logo {
      text-align: center;
      flex-direction: column;
    }
  
    
  }
  
  